import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { searchClient } from '../../searchClient';
import searchItemUrl from '../utils/searchItemUrl';
import querySuggestionsHeaderTemplate from '../templates/querySuggestionsHeaderTemplate';
import querySuggestionsItemTemplate from '../templates/querySuggestionsItemTemplate';
import getAttributeValue from '../../../../utils/getAttributeValue';
import sanitizeQuery from '../utils/sanitizeQuery';

const querySuggestionsPlugin = createQuerySuggestionsPlugin({
	searchClient,
	indexName: getAttributeValue(
		'.header-algolia-search__results',
		'data-query-suggestions-index',
	),

	getSearchParams() {
		return { hitsPerPage: 4 };
	},

	transformSource({ source }) {
		return {
			...source,
			onSelect({ setIsOpen }) {
				setIsOpen(false);
			},
			getItemUrl({ item }) {
				return searchItemUrl(sanitizeQuery(item.query));
			},
			templates: {
				...source.templates,
				header({ items, html }) {
					if (items.length === 0) return null;

					return querySuggestionsHeaderTemplate(html);
				},
				item(params) {
					const { item, html } = params;
					const { children } = source.templates.item(params).props;

					return querySuggestionsItemTemplate(
						html,
						children,
						sanitizeQuery(item.query),
					);
				},
			},
		};
	},
});

export default querySuggestionsPlugin;
