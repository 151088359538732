const excludeAlgoliaProductsDataDeletedFromBasket = (
	afterAlgoliaSearchStorageData = [],
	algoliaPurchasedProductsSkus = [],
) => {
	if (!afterAlgoliaSearchStorageData || !algoliaPurchasedProductsSkus) return;

	const algoliaPurchasedProductsDataSet = new Set(
		afterAlgoliaSearchStorageData,
	);

	algoliaPurchasedProductsDataSet.forEach(productData => {
		if (!algoliaPurchasedProductsSkus.includes(productData.productCode)) {
			algoliaPurchasedProductsDataSet.delete(productData);
		}
	});

	// returns queryID and productCode(sku)
	return Array.from(algoliaPurchasedProductsDataSet);
};

export default excludeAlgoliaProductsDataDeletedFromBasket;
