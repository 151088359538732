const getUserToken = () => {
	window?.aa('getUserToken', {}, (error, userToken) => {
		if (error) {
			console.error(`getUserToken: ' + ${error}`); // eslint-disable-line no-console
			return;
		}
		return userToken;
	});
};

export default getUserToken;
