import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights';
import {
	algoliaApplicationId,
	algoliaReadOnlyApiKey,
} from '../../../searchClient';
import setUserToken from './userToken/setUserToken';
import setAuthenticatedUserToken from './userToken/setAuthenticatedUserToken';
import addToCartSubscribe from './events/addToCartSubscribe';
import handlePurchasedObject from './events/purchasedObject/handlePurchasedObject';

const algoliaSearch = document.querySelector('.header-algolia-search');
const orderConfirmation = document.querySelector('.page-orderConfirmationPage');

window?.aa('init', {
	appId: algoliaApplicationId,
	apiKey: algoliaReadOnlyApiKey,
	useCookie: true,
});

const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({
	insightsClient: window?.aa,
});

setUserToken();
setAuthenticatedUserToken();

if (algoliaSearch) addToCartSubscribe();
if (orderConfirmation) handlePurchasedObject();

export default algoliaInsightsPlugin;
