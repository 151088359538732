import hoverintent from 'hoverintent';
import isTouchDevice from '../../utils/isTouchDevice';

export default class HoverPanel {
	constructor() {
		this.hoverClass = 'hover-panel--active';
		this.hoverElementSelector = '[data-hover-intent="true"]';
		this.clickElementSelector = '[data-click-event="true"]';
		this.contentSelector = '[data-panel-content]';
		this.isOpened = false;
		this.isTouchDevice = isTouchDevice();

		this.init();
	}

	attachHoverEvents() {
		if (!this.hoverEventsAreAttached) {
			this.hoverElements.forEach(el => {
				this.attachHoverEvent(el);
			});

			this.hoverEventsAreAttached = true;
		}
	}

	attachHoverEvent(el) {
		hoverintent(
			el,
			() => {
				this.closeAllPanels();
				this.checkAndTriggerAjaxEvent(el);
				this.toggleHoverClass(el);
			},
			() => this.toggleHoverClass(el),
		);
	}

	checkAndTriggerAjaxEvent(el) {
		const ajaxType = el.getAttribute('data-ajax');

		if (ajaxType) {
			const event = new Event(ajaxType);
			el.dispatchEvent(event);
		}
	}

	attachClickEvents() {
		if (!this.clickEventsAreAttached) {
			this.clickElements.forEach(el => {
				this.attachClickEvent(el);
			});

			this.closeOnClickOutside();

			this.clickEventsAreAttached = true;
		}
	}

	attachClickEvent(element) {
		element.addEventListener('click', event => {
			event.stopPropagation();

			const { target } = event;
			const isContentChild = target.closest(this.contentSelector);
			if (!isContentChild && !this.isTouchDevice) {
				event.preventDefault();
			}

			const isAnchorTag = element.querySelector('a.hover-panel__main-link');
			if (isAnchorTag && this.isTouchDevice) return;

			if (!element.classList.contains(this.hoverClass)) {
				this.closeAllPanels();
				this.toggleHoverClass(element);
				this.isOpened = true;
			} else {
				const isContent = target.hasAttribute(this.contentSelector);

				if (!isContent && !isContentChild) {
					this.toggleHoverClass(element);
					this.isOpened = false;
				}
			}

			this.checkAndTriggerAjaxEvent(element);
		});
	}

	closeOnClickOutside() {
		document.addEventListener('click', event => {
			if (this.isOpened) {
				const { target } = event;
				const isHoverPanel =
					target.getAttribute('data-click-event') === 'true' ||
					target.getAttribute('data-hover-intent') === 'true';
				const isChildElement =
					target.closest(this.clickElementSelector) ||
					target.closest(this.hoverElementSelector);

				if (!isHoverPanel && !isChildElement) this.closeAllPanels();
			}
		});
	}

	closeAllPanels() {
		this.clickElements.forEach(el => {
			const openedElement = el.classList.contains(this.hoverClass);
			if (openedElement) this.toggleHoverClass(el);
		});
	}

	toggleHoverClass(el) {
		el.classList.toggle(this.hoverClass);
	}

	attachEvents() {
		if (!this.isTouchDevice || (this.isTouchDevice && this.isMouseDetected)) {
			this.attachHoverEvents();
		} else {
			this.clickElements = [...this.hoverElements, ...this.clickElements];
		}
		this.attachClickEvents();
	}

	init() {
		this.hoverElements = document.querySelectorAll(this.hoverElementSelector);
		this.clickElements = document.querySelectorAll(this.clickElementSelector);
		this.isMouseDetected = false;

		this.attachEvents();

		document.addEventListener('mousemove', () => {
			if (!this.isMouseDetected && !this.touchScreenIsUsed) {
				this.isMouseDetected = true;
				this.attachEvents();
			}
		});

		document.addEventListener('touchstart', () => {
			this.touchScreenIsUsed = true;
		});
	}
}
