import initAlgoliaSearch from '../algolia/initAlgoliaSearch';
import ChannelSelector from './ChannelSelector';
import MobileNavigation from './MobileNavigation';
import SearchForm from '../search/SearchForm';
import HoverPanel from './HoverPanel';
import MiniCart from './MiniCart';
import languageSelector from './LanguageSelector';
import togglePillSwitch from '../togglePillSwitch';
import headerAffiliateBanner from './headerAffiliateBanner';

const breakpoints = {
	lg: 992,
};

export default class header {
	constructor(id) {
		this.element = document.getElementById(id);
		this.algoliaSearch = document.querySelector('.header-algolia-search');
		this.STICKY_CLASS = 'page__header--is-sticky';
		const stickyHeaderPageList = [
			'home',
			'campaign',
			'category',
			'search',
			'supergroup',
		];
		this.headerUtilityBar = document.getElementById('headerUtilityBar');
		this.secondaryNavigation = document.getElementById(
			'siteSecondaryNavigation',
		);
		this.lastScrollTop = 0;
		this.algoliaSearchDropdown = document.querySelector(
			'.header-algolia-search__results',
		);
		this.searchDropdown = document.querySelector('.autocomplete-results');

		if (this.element) {
			if (this.algoliaSearch) initAlgoliaSearch();
			else this.setupSearchForm();

			new ChannelSelector('UtilBarChannelSwitcher');
			new HoverPanel();
			new MobileNavigation();
			new MiniCart();
			languageSelector();
			togglePillSwitch();
			headerAffiliateBanner();

			if (
				window.dataLayer &&
				stickyHeaderPageList.includes(window.dataLayer[0].navigation.pageType)
			) {
				window.addEventListener('scroll', () => {
					if (window.innerWidth >= breakpoints.lg) {
						this.maybeAddOrRemoveClassFromElem(
							this.headerUtilityBar,
							window.pageYOffset,
							false,
						);
					} else {
						this.maybeAddOrRemoveClassFromElem(
							this.element,
							window.pageYOffset,
							true,
						);

						this.lastScrollTop = window.pageYOffset;
					}
				});
			}
		}
	}

	maybeAddOrRemoveClassFromElem(elem, windowPosFromTop, isMobile) {
		const headerClasses = this.element.classList;
		const headerHasStickyClass = headerClasses.contains(this.STICKY_CLASS);
		const posToStartStickyAfter = isMobile
			? this.element.offsetHeight + 50
			: elem.offsetTop + elem.offsetHeight;
		const isScrollUp = windowPosFromTop < this.lastScrollTop;

		if (windowPosFromTop > posToStartStickyAfter && !headerHasStickyClass) {
			if (isMobile) {
				if (isScrollUp) {
					setTimeout(() => {
						headerClasses.add(this.STICKY_CLASS);
					}, 500);
				} else {
					if (this.searchDropdown && this.searchDropdown.innerHTML.length > 0) {
						this.searchDropdown.innerHTML = '';
						document.querySelector('.search-bar__input').blur();
					}

					if (
						this.algoliaSearchDropdown &&
						this.algoliaSearchDropdown.innerHTML.length > 0
					) {
						this.algoliaSearchDropdown.innerHTML = '';
						document.querySelector('.aa-Input').blur();
					}
				}
			} else {
				headerClasses.add(this.STICKY_CLASS);

				if (this.secondaryNavigation) {
					this.secondaryNavigation.classList.add(
						'site-secondary-navigation--no-shadow',
					);
					return;
				}
			}
		}

		if (windowPosFromTop <= posToStartStickyAfter && headerHasStickyClass) {
			headerClasses.remove(this.STICKY_CLASS);

			if (this.secondaryNavigation) {
				this.secondaryNavigation.classList.remove(
					'site-secondary-navigation--no-shadow',
				);
			}
		}

		if (isMobile && windowPosFromTop > this.lastScrollTop) {
			headerClasses.remove(this.STICKY_CLASS);
		}
	}

	setupSearchForm() {
		const searchOptions = (window.od && window.od.searchOptionsHeader) || {};
		this.searchForm = new SearchForm(searchOptions);
	}
}
