import Autocomplete from 'global/form/autocomplete/Autocomplete';

import RecentSearchData from './recentSearchData';
import SearchAutocompleteData from './SearchAutocompleteData';

const defaultOptions = {
	autocompleteUrl: '/search/autocomplete/headerSearchBox',
	minCharactersBeforeRequest: 3,
	waitTimeBeforeRequest: 500,
	displayProductImages: true,
};

export default class SearchForm {
	constructor(options) {
		this.element = document.getElementById(options.element);
		if (this.element) {
			const clearOptions = {
				form: this.element,
				resetFormTrigger: this.element.querySelector(
					`#${options.resetFormTrigger}`,
				),
			};

			this.options = Object.assign({}, defaultOptions, options, clearOptions);
			this.recentSearchData = new RecentSearchData(
				options.recentSearchTerms,
				options.urlPrefix,
			);
			this.searchData = new SearchAutocompleteData(this.options, searchTerm =>
				this.recentSearchData.search(searchTerm),
			);

			this.element.addEventListener('submit', event => this.onSubmit(event));

			this.inputElement = this.element.querySelector('input[type=search]');

			this.autocomplete = new Autocomplete(
				this.inputElement,
				this.options,
				searchTerm => this.searchData.search(searchTerm),
			);
		}
	}

	onSubmit(event) {
		if (!this.inputElement.value) {
			event.preventDefault();
		}
	}
}
