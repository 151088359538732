import hoverintent from 'hoverintent';

class SiteSecondaryNavigation {
	constructor() {
		this.secondaryNav = document.getElementById('siteSecondaryNavigation');
		if (this.secondaryNav) {
			this.secondaryNavLinks = this.secondaryNav.querySelectorAll(
				'[data-nav-children="true"]',
			);
			this.hoverEvents = [];
			this.hoverClass = 'hover';
		}
	}

	toggleSecondaryHoverClass(el) {
		el.classList.toggle(this.hoverClass);
	}

	attachSecondaryNavHoverEvent() {
		this.secondaryNavLinks.forEach((el, i) => {
			this.hoverEvents[i] = hoverintent(
				el,
				() => {
					this.toggleSecondaryHoverClass(el);
				},
				() => this.toggleSecondaryHoverClass(el),
			);
		});
	}

	init() {
		if (this.secondaryNav && this.secondaryNavLinks.length > 0) {
			this.attachSecondaryNavHoverEvent();
		}
	}

	destroy() {
		if (this.hoverEvents.length > 0) {
			this.hoverEvents.forEach(hoverEvent => {
				hoverEvent.remove();
			});
		}
	}
}

export default SiteSecondaryNavigation;
