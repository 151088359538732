import insightEvent from '../insightEvent';
import getCurrencyCode from '../../../../utils/getCurrencyCode';

const insightEventWithObjectData = (
	eventType,
	purchasedProductsSkus,
	objectData,
) => {
	insightEvent(eventType, {
		objectIDs: purchasedProductsSkus,
		objectData,
		currency: getCurrencyCode(),
	});
};

export default insightEventWithObjectData;
