import $ from 'jquery';

$.ajaxSetup({
	cache: true,
	beforeSend: addCsrfTokenToAjax,
});

function addCsrfTokenToAjax(xhr, settings) {
	const checkRequestMethod = method =>
		['POST', 'PUT', 'DELETE'].indexOf(method) > -1;
	const isExternalURL = url => /^http:.*/.test(url) || /^https:.*/.test(url);

	if (checkRequestMethod(settings.method) && !isExternalURL(settings.url)) {
		// Only send the token to relative URLs i.e. locally.
		xhr.setRequestHeader('CSRFToken', window.ACC.config.CSRFToken);
	}
}

function request(settings) {
	const ajaxSettings = prependLocale(settings);
	return externalRequest(ajaxSettings);
}

function externalRequest(settings) {
	return new Promise((resolve, reject) => {
		$.ajax(settings)
			.done((data, textStatus, jqXHR) => {
				const { status, statusText } = jqXHR;

				resolve({
					data,
					status,
					statusText,
				});
			})
			.fail(reject);
	});
}

function prependLocale(settings) {
	if (!settings.url) {
		return settings;
	}

	const { Cookie } = window.od;
	const localeCookie = Cookie.get('odLanguage');
	const languageSelector = document.querySelector('.language-available');
	const currentUrl = settings.url;
	const locale =
		localeCookie &&
		languageSelector !== null &&
		languageSelector.getAttribute('data-ajax-countrycode') === 'true' &&
		!currentUrl.includes(`/${localeCookie}`)
			? `/${localeCookie}`
			: '';
	return Object.assign({}, settings, {
		url: locale + settings.url,
	});
}

const Ajax = (settings = {}) => request(settings);

Ajax.get = (url, data = {}) => {
	const options = {
		method: 'GET',
		url,
		data,
	};
	return request(options);
};

Ajax.post = (url, data = {}) => {
	const options = {
		method: 'POST',
		url,
		data,
	};
	return request(options);
};

Ajax.external = externalRequest;

export default Ajax;
