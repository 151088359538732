import Ajax from 'global/od/Ajax';
import MiniCartCounter from './MiniCartCounter';

export default class MiniCart {
	constructor() {
		this.basketContainer = document.getElementById('miniCart');

		if (this.basketContainer) {
			const instance = this;
			this.basketTrigger = document.querySelector('[data-ajax="mini-cart"]');
			this.basketTrigger.addEventListener('mini-cart', () =>
				this.checkMiniCartStatus(instance),
			);

			new MiniCartCounter();
		}
	}

	checkMiniCartStatus(instance) {
		if (window.od.cart === undefined) window.od.cart = {};
		const { miniCartStale } = window.od.cart;

		if (miniCartStale || miniCartStale === undefined) {
			Object.getPrototypeOf(instance)
				.getCartHTML()
				.then(res => instance.insertIntoDOM(res, instance));
		}
	}

	getCartHTML() {
		const data = {
			url: '/ajax/cart/content',
			method: 'GET',
			dataType: 'html',
		};
		return Ajax(data);
	}

	insertIntoDOM({ data }, instance) {
		instance.basketContainer.innerHTML = data;

		window.od.cart.miniCartStale = false;
	}
}
