import getGoogleAnalyticsUserIdFromBrowserCookie from '../../../utils/getGoogleAnalyticsUserIdFromBrowserCookie';

const setUserToken = () => {
	const userToken = getGoogleAnalyticsUserIdFromBrowserCookie('_ga');

	if (userToken) {
		const algoliaUserToken = localStorage.getItem('algoliaUserToken');
		if (algoliaUserToken !== userToken) {
			window?.aa('setUserToken', userToken);
			localStorage.setItem('algoliaUserToken', userToken);
		}
	} else if (!userToken && localStorage.getItem('algoliaUserToken') !== null) {
		localStorage.removeItem('algoliaUserToken');
	}
};

export default setUserToken;
