import suggestionTemplate from 'global/form/autocomplete/suggestionsTemplate';
import productTemplate from 'global/form/autocomplete/productTemplate';
import deviceTemplate from 'global/form/autocomplete/deviceTemplate';
import Ajax from 'global/od/Ajax';
import reduceSuggestions from './reducers/suggestions';
import reduceProducts from './reducers/products';
import reduceDevices from './reducers/devices';

const defaultConfig = {
	minCharactersBeforeRequest: 3,
	baseUrl: window.location.origin,
};

export default class SearchAutocompleteData {
	constructor(config, fallbackDataFunction) {
		this.config = Object.assign({}, defaultConfig, config);
		this.fallbackDataFunction = fallbackDataFunction;
		this.currentSearchTerm = '';
	}

	search(searchTerm) {
		this.currentSearchTerm = searchTerm || '';

		return new Promise(resolve => {
			if (this.useFallBack(searchTerm)) {
				const fallbackData = {
					title:
						(this.config.translations &&
							this.config.translations.recentSearchTitle) ||
						'',
					items: this.fallbackDataFunction(searchTerm),
				};
				return resolve(fallbackData);
			}

			return this.searchRequest(searchTerm).then(results => {
				resolve(this.processResults(results));
			});
		});
	}

	useFallBack(searchTerm) {
		return (
			!searchTerm || searchTerm.length < this.config.minCharactersBeforeRequest
		);
	}

	searchRequest(searchTerm) {
		return Ajax.external({
			method: 'POST',
			url: this.config.autocompleteUrl,
			data: {
				term: searchTerm,
			},
		}).then(xhrResponse => xhrResponse.data);
	}

	processResults(results) {
		const autocompleteData = {
			items: [],
		};

		let suggestions = [];
		let products = [];
		let devices = [];

		if (results.suggestions) {
			suggestions = this.processSuggestions(results.suggestions);
		}
		if (results.products) {
			products = this.processProducts(results.products);
		}
		if (results.devices) {
			devices = this.processDevices(results.devices);
		}

		autocompleteData.items = [].concat(suggestions, products, devices);
		return autocompleteData;
	}

	processSuggestions(suggestions) {
		const out = reduceSuggestions(
			suggestions,
			itemTitle => this.highlightSearchTerm(itemTitle),
			this.config.baseUrl,
		);
		out.forEach(suggestion => {
			suggestion.template = suggestionTemplate(suggestion, this.config);
		});
		return out;
	}

	processProducts(products) {
		const out = reduceProducts(
			products,
			itemTitle => this.highlightSearchTerm(itemTitle),
			this.config.baseUrl,
		);
		out.forEach(product => {
			product.template = productTemplate(product, this.config);
		});
		return out;
	}

	processDevices(devices) {
		const out = reduceDevices(
			devices,
			itemTitle => this.highlightSearchTerm(itemTitle),
			this.config.baseUrl,
			this.config.inkTonerURL,
		);
		out.forEach(device => {
			device.template = deviceTemplate(device, this.config);
		});
		return out;
	}

	highlightSearchTerm(itemTitle) {
		const regEx = new RegExp(this.currentSearchTerm, 'gi');
		return itemTitle.replace(
			regEx,
			'<span class="autocomplete-results-list__termhighlite">$&</span>',
		);
	}
}
