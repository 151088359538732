export default function render(device, config) {
	function ifOptionLabelExists() {
		if ('optionLabel' in config.translations) {
			return `<span class="autocomplete-results-list__label">${config.translations.optionLabel}</span>`;
		}
		return '';
	}

	return `<a href="${
		device.url
	}" class="autocomplete-results-list__link autocomplete-results-list__link--product clearfix">
	<span class="autocomplete-results-list__thumb">
		<span class="autocomplete-results-list__device-placeholder"></span>
	</span>
	<span class="autocomplete-results-list__desc">
		${ifOptionLabelExists()}
		<span class="autocomplete-results-list__title">${device.formattedTitle}</span>
	</span>
</a>`;
}
