import getQueryValue from './getQueryValue';

const debounceDelay = 120;

export const debouncePromise = (fn, delay) => {
	let timerId;

	return (...args) => {
		const query = getQueryValue();
		if (timerId) clearTimeout(timerId);

		return new Promise(resolve => {
			const timeout = query?.length === 0 || query?.length === 1 ? 0 : delay;
			timerId = setTimeout(() => resolve(fn(...args)), timeout);
		});
	};
};

const debounce = debouncePromise(
	items => Promise.resolve(items),
	debounceDelay,
);

export default debounce;
