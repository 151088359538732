const addedToCartObjectIDsAfterSearchStorage = (queryID, productCode) => {
	const productsAfterAlgoliaSearch = getProductsAfterAlgoliaSearchStorageData();

	if (!productsAfterAlgoliaSearch) {
		setProductsAfterAlgoliaSearchStorage([{ queryID, productCode }]);
	} else {
		const storedProducts = productsAfterAlgoliaSearch;
		if (storedProducts.some(product => product.productCode === productCode))
			return;

		storedProducts.push({ queryID, productCode });
		setProductsAfterAlgoliaSearchStorage(storedProducts);
	}
};

const setProductsAfterAlgoliaSearchStorage = item => {
	localStorage.setItem('productsAfterAlgoliaSearch', JSON.stringify(item));
};

export const getProductsAfterAlgoliaSearchStorageData = () => {
	const getStoredData = localStorage.getItem('productsAfterAlgoliaSearch');
	let storedData;

	try {
		storedData = JSON.parse(getStoredData);
	} catch (exception) {
		storedData = null;
	}
	return storedData;
};

export const removeProductsAfterAlgoliaSearchStorage = () => {
	localStorage.removeItem('productsAfterAlgoliaSearch');
};

export default addedToCartObjectIDsAfterSearchStorage;
