import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { searchClient } from '../../searchClient';
import { INSTANT_SEARCH_INDEX_NAME } from '../../algoliaInstantSearch/instantSearch';

const productSuggestionsConfig = query => {
	return getAlgoliaResults({
		searchClient,
		queries: [
			{
				indexName: INSTANT_SEARCH_INDEX_NAME,
				query,
				params: {
					hitsPerPage: 3,
					filters: 'includeInResults:true',
					ruleContexts: ['enable-redirect-url'],
					clickAnalytics: true,
				},
			},
		],
	});
};

export default productSuggestionsConfig;
