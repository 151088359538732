import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import setInstantSearchUiState from '../../algoliaInstantSearch/setInstantSearchUiState';
import searchItemUrl from '../utils/searchItemUrl';
import isModifierEvent from '../utils/isModifierEvent';
import recentSearchesItemTemplate from '../templates/recentSearchesItemTemplate';
import recentSearchesHeaderTemplate from '../templates/recentSearchesHeaderTemplate';
import sanitizeQuery from '../utils/sanitizeQuery';

const onSelect = ({ setIsOpen, setQuery, event, query }) => {
	// You want to trigger the default browser behavior if the event is modified.
	if (isModifierEvent(event)) return;

	const sanitizedQuery = sanitizeQuery(query);

	setQuery(sanitizedQuery);
	setIsOpen(true);
	setInstantSearchUiState({ query: sanitizedQuery });
};

const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
	key: 'RECENT_SEARCH',
	limit: 5,
	transformSource({ source }) {
		return {
			...source,
			getItemUrl({ item }) {
				return searchItemUrl(item.label);
			},
			onSelect({ setIsOpen, setQuery, item, event }) {
				onSelect({
					setQuery,
					setIsOpen,
					event,
					query: sanitizeQuery(item.label),
				});
			},
			// Update the default `item` template to wrap it with a link
			// and plug it to the InstantSearch router.
			templates: {
				...source.templates,
				header({ items, html }) {
					if (items.length === 0) return null;

					return recentSearchesHeaderTemplate(html);
				},
				item(params) {
					const { children } = source.templates.item(params).props;

					return recentSearchesItemTemplate({
						query: sanitizeQuery(params.item.label),
						children,
						html: params.html,
					});
				},
			},
		};
	},
});

export default recentSearchesPlugin;
