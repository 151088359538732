import getAttributeValue from '../../../../utils/getAttributeValue';

const productSuggestionsTitle = getAttributeValue(
	'.header-algolia-search__results',
	'data-product-suggestions-title',
);

const productSuggestionsHeaderTemplate = html => {
	return html`<span class="aa-SourceHeaderTitle"
		>${productSuggestionsTitle}</span
	>`;
};

export default productSuggestionsHeaderTemplate;
