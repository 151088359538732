import instantsearch from 'instantsearch.js';
import { history } from 'instantsearch.js/es/lib/routers';
import { searchClient } from '../searchClient';
import getAttributeValue from '../../../utils/getAttributeValue';
import initWidgets from './initWidgets';

// TODO: should we move instantSearch inside browse folder

const algoliaSearchListPageDiv = document.querySelector(
	'.algolia-search-list-page',
);
const algoliaSearchResultsDiv = algoliaSearchListPageDiv?.querySelector(
	'.algolia-search-list-page__products',
);

export const INSTANT_SEARCH_INDEX_NAME = getAttributeValue(
	'.header-algolia-search',
	'data-search-index',
);

export const algoliaSearch = instantsearch({
	searchClient,
	indexName: INSTANT_SEARCH_INDEX_NAME,
	insights: true,
	insightsClient: window.aa,
	clickAnalytics: true,
	routing: {
		router: history({
			windowTitle(routeState) {
				const indexRouteState = routeState || {};

				if (algoliaSearchListPageDiv && indexRouteState?.text) {
					const browserPageTitleMsgs = algoliaSearchListPageDiv?.dataset;
					let message =
						indexRouteState.text.charAt(0).toUpperCase() +
						indexRouteState.text.slice(1);
					message += ` | ${browserPageTitleMsgs?.searchMessage ?? 'Search'} | Viking Direct ${browserPageTitleMsgs?.currentMarket ?? ''}`;
					return message;
				}
			},
		}),
		stateMapping: {
			stateToRoute(uiState) {
				const indexUiState = uiState[INSTANT_SEARCH_INDEX_NAME];

				return {
					text: indexUiState.query,
					page: indexUiState.page,
					refinementList: indexUiState.refinementList,
					range: indexUiState.range,
				};
			},
			routeToState(routeState) {
				return {
					[INSTANT_SEARCH_INDEX_NAME]: {
						query: routeState.text,
						page: routeState.page,
						refinementList: routeState.refinementList,
						range: routeState.range,
					},
				};
			},
		},
	},
	onStateChange({ uiState, setUiState }) {
		setUiState(uiState);
	},
	searchFunction(helper) {
		if (algoliaSearchResultsDiv) {
			algoliaSearchResultsDiv.style.display =
				helper.state.query === '' ? 'none' : '';

			helper.search();
		}
	},
});

initWidgets(algoliaSearch);
