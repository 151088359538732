import { getPurchasedProductSkus } from '../../../../utils/getPurchasedProductSkus';
import handleInsightWithObjectData from '../objectData/handleInsightWithObjectData';

const handlePurchasedObject = () => {
	const purchasedProductsSkus = getPurchasedProductSkus();

	handleInsightWithObjectData(
		purchasedProductsSkus,
		'purchasedObjectIDs',
		'purchasedObjectIDsAfterSearch',
	);
};

export default handlePurchasedObject;
