const getAuthenticatedUserToken = () => {
	window?.aa('getAuthenticatedUserToken', {}, (error, authUserToken) => {
		if (error) {
			console.error(`getAuthenticatedUserToken: ${error}`); // eslint-disable-line no-console
			return;
		}
		return authUserToken;
	});
};

export default getAuthenticatedUserToken;
