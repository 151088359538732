import { algoliaSearch, INSTANT_SEARCH_INDEX_NAME } from './instantSearch';

const setInstantSearchUiState = indexUiState => {
	algoliaSearch.setUiState(uiState => ({
		...uiState,
		[INSTANT_SEARCH_INDEX_NAME]: {
			...uiState[INSTANT_SEARCH_INDEX_NAME],
			page: 1,
			...indexUiState,
		},
	}));
};

export default setInstantSearchUiState;
