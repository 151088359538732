const defaultConfig = {
	waitTimeBeforeRequest: 500, // milliseconds
};

const keyboardHighlightClass =
	'autocomplete-results-list__item--is-highlighted';

export default class Autocomplete {
	/**
	 * @param domElement - Input element to attach to
	 * @param config - set of config. Check defaultConfig for available config.
	 * @param searchFunction - a function that returns a promise. The resolution of the
	 *        promise should provide an object with an optional 'title' and 'items'
	 */
	constructor(domElement, config, searchFunction) {
		this.inputElement = domElement;
		this.config = { ...defaultConfig, ...config };
		this.searchFunction = searchTerm => searchFunction(searchTerm);

		this.results = null;
		this.queueInterval = null;

		this.resultSelected = false;
		this.selectedIndex = -1;

		this.resultsElement = this.config.form.querySelector(
			'.autocomplete-results',
		);

		if (this.resultsElement) {
			this.recentSearchTitle = this.resultsElement.getAttribute(
				'data-recent-search-title',
			);

			this.prepareInputElement();
			this.bindEvents();
			this.recentSearches = false;
		}
	}

	bindEvents() {
		this.resultsElement.addEventListener('mousedown', () => {
			this.resultSelected = true;
		});
		this.resultsElement.addEventListener('mouseleave', () => {
			this.resultSelected = false;
		});

		this.inputElement.addEventListener('focus', event =>
			this.handleInputChange(event),
		);
		this.inputElement.addEventListener('input', event =>
			this.handleInputChange(event),
		);
		this.inputElement.addEventListener('blur', () => this.handleBlur());
		this.inputElement.addEventListener('keyup', event =>
			this.handleKeyInput(event),
		);
		this.inputElement.form.addEventListener('submit', event =>
			this.handleSubmit(event),
		);

		if (this.config.resetFormTrigger) {
			this.config.resetFormTrigger.addEventListener('click', () =>
				this.resetForm(),
			);
		}
	}

	prepareInputElement() {
		this.inputElement.autocomplete = 'off';
		this.showClearButton();
	}

	handleInputChange(event) {
		const { value } = event.target;

		this.resultSelected = false;
		this.queueSearch(value);
		this.showClearButton();
	}

	showClearButton() {
		const { value } = this.inputElement;
		if (!this.config.form || !this.config.form.classList) {
			return;
		}

		if (value && value.length > 0) {
			this.config.form.classList.add('search-bar--is-populated');
		} else {
			this.config.form.classList.remove('search-bar--is-populated');
		}
	}

	resetForm() {
		this.config.form.reset();
		this.inputElement.value = '';
		this.resetIndex();
		this.showClearButton();
		this.inputElement.focus();
	}

	handleSubmit(event) {
		if (this.selectedIndex > -1) {
			event.preventDefault();
			this.activateSelected();
		}
	}

	isResultsElementActive() {
		return !!document.activeElement.closest('.autocomplete-results');
	}

	handleBlur() {
		// resultSelected for chrome & FF, isResultsElementActive for IE11 (FEP-190)
		if (!(this.resultSelected || this.isResultsElementActive())) {
			this.clearSearchQueue();
			this.removeSuggestions();
		}
	}

	queueSearch(searchTerm) {
		this.clearSearchQueue();
		const delay = parseInt(this.config.waitTimeBeforeRequest, 10);
		this.queueInterval = setTimeout(
			() => this.performSearch(searchTerm),
			delay,
		);
	}

	clearSearchQueue() {
		if (this.queueInterval) {
			clearTimeout(this.queueInterval);
		}
	}

	performSearch(searchTerm) {
		return this.searchFunction(searchTerm)
			.then(results => {
				this.results = results;
				this.render(results);
			})
			.catch(() => this.renderError());
	}

	render(results) {
		const html = this.populateTemplate(results);
		this.resultsElement.innerHTML = html;
		this.resetIndex();
	}

	populateTemplate(results) {
		const { items, title } = results;

		if (!items || items.length === 0) {
			return '';
		}

		this.recentSearches = items[0].type === 'recentSearch';

		const titleMarkup = title
			? `<li class="autocomplete-results-list__recent-title">${title}</li>`
			: '';
		const recentSearchesMarkup = this.recentSearches
			? `<li class="autocomplete-results-list__recent-title">${this.recentSearchTitle}</li>`
			: '';
		const itemsMarkup = items
			.map(
				item =>
					`<li class="autocomplete-results-list__item autocomplete-results-list__item--${
						item.type
					}">
					${item.template}
					${
						item.type === 'suggestions'
							? `<svg class="autocomplete-results-list__search" aria-hidden="true"><use xlink:href="#icnMagnifierNew" /></svg>`
							: ''
					}
			</li>`,
			)
			.join('');

		return `<ul class="autocomplete-results-list">${titleMarkup}${recentSearchesMarkup}${itemsMarkup}</ul>`;
	}

	removeSuggestions() {
		this.resultsElement.innerHTML = '';
	}

	renderError() {
		this.removeSuggestions();
	}

	handleKeyInput(event) {
		switch (event.key) {
			case 'Down':
			case 'ArrowDown':
				this.moveIndex(1);
				event.preventDefault();
				break;
			case 'Up':
			case 'ArrowUp':
				this.moveIndex(-1);
				event.preventDefault();
				break;
			case 'Right':
			case 'ArrowRight':
				this.queueSearch(this.inputElement.value);
				break;
			case 'Esc':
			case 'Escape':
				this.resetIndex();
				this.removeSuggestions();
				break;
			default:
		}
	}

	moveIndex(direction) {
		const oldIndex = this.selectedIndex;
		if (this.results.items.length > 0) {
			let newIndex = Math.max(this.selectedIndex, -1) + direction;
			newIndex = newIndex >= this.results.items.length ? 0 : newIndex;
			this.selectedIndex = newIndex;
		}
		this.onSelectedIndexUpdated(this.selectedIndex, oldIndex);
	}

	onSelectedIndexUpdated(newValue, oldValue) {
		if (oldValue === newValue) {
			return;
		}

		this.clearKeyboardHighlight();
		this.setKeyboardHighlight(newValue);
		this.updateWithSelected();
	}

	updateWithSelected() {
		if (this.results.items && this.results.items[this.selectedIndex]) {
			this.inputElement.value = this.results.items[this.selectedIndex].term;
		} else {
			this.inputElement.value = '';
		}
	}

	activateSelected() {
		if (
			this.results.items &&
			this.results.items[this.selectedIndex] &&
			this.results.items[this.selectedIndex].url
		) {
			window.od.util.setLocation(this.results.items[this.selectedIndex].url);
		}
	}

	resetIndex() {
		this.selectedIndex = -1;
		this.clearKeyboardHighlight();
	}

	setKeyboardHighlight(index) {
		const toSelect = this.resultsElement.querySelectorAll(
			'.autocomplete-results-list__item',
		)[index];
		return toSelect && toSelect.classList.add(keyboardHighlightClass);
	}

	getHighlightedResult() {
		return this.resultsElement.querySelector(`.${keyboardHighlightClass}`);
	}

	clearKeyboardHighlight() {
		const selected = this.getHighlightedResult();
		return selected && selected.classList.remove(keyboardHighlightClass);
	}
}
