import Cookie from '../../../../global_critical/od/Cookie';

const extractGoogleAnalyticsUserIdFromCookie = gaCookie => {
	if (gaCookie) {
		// Remove the Google Analytics tracker from the device ID.
		const userIdParts = gaCookie.split('.').slice(-2);
		if (userIdParts.length === 2) {
			return userIdParts.join('_');
		}
	}
	return undefined;
};

const getGoogleAnalyticsUserIdFromBrowserCookie = cookieName => {
	const browserCookie = Cookie.get(cookieName);

	if (!browserCookie) return undefined;

	return extractGoogleAnalyticsUserIdFromCookie(browserCookie);
};

export default getGoogleAnalyticsUserIdFromBrowserCookie;
