export default function products(results, formatter, baseUrl) {
	return results.map(item => ({
		type: 'products',
		url: `${baseUrl}/${item.url}`,
		imageUrl: item.imageUrl,
		term: item.title,
		title: item.title,
		formattedTitle: (formatter && formatter(item.title)) || item.title,
		formattedValue: item.price ? item.price.formattedValue : null,
		unitOfMeasure: item.unitOfMeasure,
	}));
}
