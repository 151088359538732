import Ajax from 'global/od/Ajax';

export default class MiniCartCounter {
	constructor() {
		document.addEventListener('cartUpdated', MiniCartCounter.getMiniCartQty);
	}

	static getBasketJSON() {
		return Ajax({
			url: '/ajax/cart/content',
			method: 'GET',
			dataType: 'json',
		});
	}

	static getMiniCartQty() {
		return MiniCartCounter.getBasketJSON().then(result => {
			const { data } = result;
			MiniCartCounter.updateMiniCartQty(data.totalUnitsCount);
		});
	}

	static updateMiniCartQty(qty) {
		const miniCartQty = document.querySelectorAll('[data-mini-cart-qty]');
		const miniCartBtnHeader = document.querySelector(
			'.icon-element--icnBasket',
		);
		const miniCartBtnFooter = document.getElementById('miniCartBtnFooter');

		if (miniCartQty.length) {
			for (let i = 0; i < miniCartQty.length; i++) {
				miniCartQty[i].innerHTML = qty;
			}
		} else {
			const createQtySpanHeader = document.createElement('span');
			createQtySpanHeader.setAttribute('class', 'icon-element__bubble');
			createQtySpanHeader.setAttribute('data-mini-cart-qty', 'miniCartQty');
			createQtySpanHeader.appendChild(document.createTextNode(qty));
			miniCartBtnHeader.appendChild(createQtySpanHeader);

			const createQtySpanFooter = document.createElement('span');
			createQtySpanFooter.setAttribute('class', 'footer-tools__cart-qty');
			createQtySpanFooter.setAttribute('data-mini-cart-qty', 'miniCartQty');
			createQtySpanFooter.appendChild(document.createTextNode(qty));
			miniCartBtnFooter.appendChild(createQtySpanFooter);
		}

		if (window.od.cart === undefined) window.od.cart = {};
		window.od.cart.miniCartStale = true;
	}
}
