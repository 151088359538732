import getUserToken from '../userToken/getUserToken';
import getAuthenticatedUserToken from '../userToken/getAuthenticatedUserToken';
import { INSTANT_SEARCH_INDEX_NAME } from '../../../../algoliaInstantSearch/instantSearch';

const insightEvent = (eventName, restOfConfiguration) => {
	window?.aa(eventName, {
		userToken: getUserToken(),
		authenticatedUserToken: getAuthenticatedUserToken(),
		eventName,
		index: INSTANT_SEARCH_INDEX_NAME,
		...restOfConfiguration,
	});
};

export default insightEvent;
