export default function render(product, options) {
	const { displayProductImages, displayPrices } = options || {};

	function productImage(productData) {
		if (displayProductImages && 'imageUrl' in productData) {
			const imageSrc = `${productData.imageUrl}?wid=48&hei=48`;
			return `<img src="${imageSrc}" alt="${productData.title}" />`;
		}
		return '';
	}

	function priceSpan(productData) {
		if (displayPrices) {
			return `<span class="autocomplete-results-list__price">${productData.formattedValue} / ${productData.unitOfMeasure}</span>`;
		}
		return '';
	}

	return `<a href="${
		product.url
	}" class="autocomplete-results-list__link autocomplete-results-list__link--product clearfix">
	<span class="autocomplete-results-list__thumb">${productImage(product)}</span>
	<span class="autocomplete-results-list__desc">
		<span class="autocomplete-results-list__title">${product.formattedTitle}</span>
		${priceSpan(product)}
	</span>
</a>`;
}
