import getAttributeValue from '../../../../utils/getAttributeValue';

const noResultsMessage = () => {
	return getAttributeValue(
		'.header-algolia-search__results',
		'data-search-no-results-text',
	);
};

export default noResultsMessage;
