export default function devices(results, formatter, baseUrl, sectionUrl) {
	return results.map(item => ({
		type: 'devices',
		className: 'autocomplete-results-list__device-search-box',
		url: returnUrl(item, baseUrl, sectionUrl),
		term: item.name,
		title: item.name,
		formattedTitle: formatter(item.name),
	}));
}

const returnUrl = (item, baseUrl, sectionUrl) => {
	if (item.deviceUrl) return `${baseUrl}${sectionUrl}/${item.deviceUrl}`;
	return `${baseUrl}${sectionUrl}/device?q=::deviceId:${item.code}`;
};
