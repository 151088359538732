import HoverIntent from 'hoverintent';
import Cookie from 'global_critical/od/Cookie';

const hoverActiveClassName = 'header-channel-selector--active';
const popupWrapper = '#UtilBarChannelSwitcher';

export default class ChannelSelector {
	constructor(idKey) {
		const isNewUser = Cookie.get('odNewUser');
		this.selectorTitle = document.getElementById(idKey);

		if (this.selectorTitle) {
			this.showIfNewUser(isNewUser);
			HoverIntent(
				this.selectorTitle,
				() => {
					this.showContainer();
				},
				() => {
					this.hideContainer();
				},
			);
			this.selectorTitle.addEventListener('keypress', openEvent =>
				this.handleOpenKeypress(openEvent),
			);
			this.selectorTitle.addEventListener('click', () =>
				this.handleClickEvent(isNewUser),
			);

			this.handleClicksOutsidePopup();
		}
	}

	handleClickEvent(isNewUser) {
		this.toggleContainer();
		if (isNewUser === 'true') {
			Cookie.set('odNewUser', 'false');
		}
	}

	handleOpenKeypress(openEvent) {
		document.addEventListener('keypress', closeEvent =>
			this.handleCloseKeypress(closeEvent),
		);
		if (openEvent.key === 'Escape') {
			this.handleCloseKeypress(openEvent);
		} else {
			this.showContainer();
		}
	}

	handleCloseKeypress(event) {
		if (event.key === 'Escape') {
			this.hideContainer();
			document.removeEventListener('keypress', this.handleCloseKeypress);
		}
	}

	showIfNewUser(isNewUser) {
		if (isNewUser === 'true') {
			this.showContainer();
		}
	}

	hideIfClickedOutside(event) {
		const clickedOutsidePopup = !event.target.closest(popupWrapper);
		if (clickedOutsidePopup) this.hideContainer();
	}

	handleClicksOutsidePopup() {
		document.addEventListener('click', event => {
			if (this.selectorTitle.classList.contains(hoverActiveClassName)) {
				this.hideIfClickedOutside(event);
			}
		});
	}

	showContainer() {
		this.selectorTitle.classList.add(hoverActiveClassName);
	}

	hideContainer() {
		this.selectorTitle.classList.remove(hoverActiveClassName);
	}

	toggleContainer() {
		this.selectorTitle.classList.toggle(hoverActiveClassName);
	}
}
