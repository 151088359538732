import getAttributeValue from '../../../../utils/getAttributeValue';

const recentSearchesTitle = getAttributeValue(
	'.header-algolia-search__results',
	'data-recent-search-title',
);

const recentSearchesHeaderTemplate = html => {
	return html`<span class="aa-SourceHeaderTitle">${recentSearchesTitle}</span>`;
};

export default recentSearchesHeaderTemplate;
