export default class MobileNavigation {
	constructor(el) {
		this.el = el;
		this.mobileNavigation = {
			navTrigger: document.getElementById('mobileMenu'),
			navWrapper: document.getElementById('siteNavigation'),
			siteSearch: document.getElementById('siteSearch'),
		};

		if (this.mobileNavigation.navWrapper && this.mobileNavigation.navTrigger) {
			this.mobileNavigationToggle();
			this.displayMobileNavigation();
		}
	}

	mobileNavigationToggle() {
		this.mobileNavigation.navTrigger.addEventListener(
			'click',
			() => {
				this.mobileNavigation.navTrigger.classList.toggle(
					'site-header__mobile-menu--is-active',
				);
				this.mobileNavigation.navWrapper.parentElement.classList.toggle(
					'site-header__navigation--is-active',
				);

				if (this.mobileNavigation.siteSearch) {
					this.searchVisabilityToggle();
				}

				if (window.innerWidth < 992) {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			},
			false,
		);
	}

	searchVisabilityToggle() {
		this.mobileNavigation.siteSearch.classList.toggle(
			'site-header__search--is-inactive',
		);
	}

	displayMobileNavigation() {
		this.mobileNavigation.navTrigger.classList.add(
			'site-header__mobile-menu--is-ready',
		);
	}
}
