import renderRecent from 'global/form/autocomplete/recentSearchTemplate';

export default class RecentSearchData {
	constructor(rawData, urlPrefix = '/search/?text=') {
		this.rawData = rawData || this.getRawData();
		this.urlPrefix = urlPrefix || '';
		this.autocompleteData = this.parseDataForAutocomplete(this.rawData);
	}

	getRawData() {
		return (
			(window.od &&
				window.od.recentSearches &&
				window.od.recentSearches.data) ||
			[]
		);
	}

	search() {
		return this.autocompleteData;
	}

	parseDataForAutocomplete(searchList) {
		return searchList
			.filter(term => term.length > 0)
			.map(term => {
				const termData = {
					type: 'recentSearch',
					term: decodeURIComponent(term),
					url: `${this.urlPrefix}${term}`,
				};
				termData.template = renderRecent(termData);
				return termData;
			});
	}
}
