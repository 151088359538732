export default function languageSelector(selector = '.js-language-selector') {
	const languageSelectorForms = document.querySelectorAll(selector);

	if (languageSelectorForms.length > 0) {
		languageSelectorForms.forEach(form => {
			const languageCheckboxes = form.querySelectorAll(
				'input[name="language"]',
			);

			languageCheckboxes.forEach(checkbox => {
				checkbox.addEventListener('change', () => {
					const href = checkbox.getAttribute('data-href');
					form.setAttribute('action', href);
				});
			});

			form.addEventListener('submit', ev => {
				ev.preventDefault();
				let currentHref = form.getAttribute('action');
				if (currentHref !== window.location.href) {
					currentHref = currentHref.endsWith('/')
						? currentHref.slice(0, -1)
						: currentHref;
					window.location.href = currentHref;
				}
			});
		});
	}
}
