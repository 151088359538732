const productSuggestionItemTemplate = (item, html) => {
	/* eslint no-underscore-dangle: 0 */
	return html` <a
		href="/${window.dataLayer[0].navigation
			?.pageLanguage}${item.productUrl}?queryID=${item.__autocomplete_queryID}"
		class="aa-ItemLink aa-ItemProduct"
	>
		<img
			class="aa-ItemImage"
			src="${window?.CDN_CONFIG?.rootUrl}${item?.imageUrl}?wid=72&hei=72"
			alt=""
			height="48"
			width="48"
		/>
		<p>${item.productTitle}</p>
	</a>`;
};

export default productSuggestionItemTemplate;
