const affiliateBannerDiv = document.getElementById('affiliateBanner');

const showAffiliateBanner = () => {
	affiliateBannerDiv?.classList.remove('d-none');
	const affiliateBannerCloseBtn = affiliateBannerDiv?.querySelector(
		'.container .header-affiliate-banner__close-button',
	);
	affiliateBannerCloseBtn?.addEventListener('click', hideAffiliateBanner);
};

const hideAffiliateBanner = () => {
	affiliateBannerDiv?.classList.add('d-none');
	sessionStorage.setItem('showAffiliateBanner', 'false');
};

const headerAffiliateBanner = () => {
	const isBannerVisible = sessionStorage.getItem('showAffiliateBanner');
	if (isBannerVisible === 'false') return;

	if (isBannerVisible === 'true') {
		showAffiliateBanner();
		return;
	}

	const URLParams = new URLSearchParams(window.location.search);

	const medium = URLParams.get('utm_medium');
	const campaign = URLParams.get('utm_campaign');

	if (medium === 'affiliate' && campaign === 'topcashback') {
		sessionStorage.setItem('showAffiliateBanner', 'true');
		showAffiliateBanner();
	}
};

export default headerAffiliateBanner;
