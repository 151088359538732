import getAttributeValue from '../../../../utils/getAttributeValue';

const querySuggestionsTitle = getAttributeValue(
	'.header-algolia-search__results',
	'data-query-suggestions-title',
);

const querySuggestionsHeaderTemplate = html => {
	return html`<span class="aa-SourceHeaderTitle"
		>${querySuggestionsTitle}</span
	>`;
};

export default querySuggestionsHeaderTemplate;
