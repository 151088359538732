import Header from 'global/header/header';
import SiteSecondaryNavigation from 'global/siteSecondaryNavigation';

const breakpoints = {
	lg: 992,
};

new Header('header');

// Site Secondary Navigation
if (window.innerWidth >= breakpoints.lg) {
	const secondaryNavigation = new SiteSecondaryNavigation();
	secondaryNavigation.init();
}