import { autocomplete } from '@algolia/autocomplete-js';
import recentSearchesPlugin from './plugins/recentSearchesPlugin';
import querySuggestionsPlugin from './plugins/querySuggestionsPlugin';
import algoliaInsightsPlugin from './plugins/algoliaInsightsPlugin/algoliaInsightsPlugin';
import redirectUrlPlugin from './plugins/redirectUrlPlugin';
import debounce from './utils/debounce';
import productSuggestionsSource from './sources/productSuggestionsSource';
import searchItemUrl from './utils/searchItemUrl';
import getAttributeValue from '../../../utils/getAttributeValue';
import sanitizeQuery from './utils/sanitizeQuery';

// TODO: decouple instance search and autocomplete

// Status management
const status = {
	resultsPending: false,
	redirectURL: '',
};

// Helper: Query DOM element
const queryElement = selector => document.querySelector(selector);

// Helper: Manage event listeners
const manageEventListener = (element, event, callback, action = 'add') => {
	if (element) element[`${action}EventListener`](event, callback);
};

// Placeholder text with fallback
const placeholderText = getAttributeValue(
	'[data-search-placeholder]',
	'data-search-placeholder',
);

// Disable Enter key functionality
const disableEnterKey = event => {
	if (status.resultsPending && event.key === 'Enter') {
		event.preventDefault(); // Prevent Enter key default action
		console.warn('Enter key disabled while fetching search results.'); // eslint-disable-line no-console
	}
};

// Toggle form actions based on resultsPending
const toggleFormActions = () => {
	const searchInput = queryElement('#headerAlgoliaSearchBox input');
	const action = status.resultsPending ? 'add' : 'remove';
	manageEventListener(searchInput, 'keydown', disableEnterKey, action);
};

// Handle mutation observer results
const handleStalledResults = mutation => {
	const { target } = mutation;
	status.resultsPending = target.classList.contains('aa-Panel--stalled');
	toggleFormActions();
};

// Setup Mutation Observer for class changes
const setupObserver = panelElement => {
	const observer = new MutationObserver(mutations => {
		mutations.forEach(mutation => {
			if (
				mutation.type === 'attributes' &&
				mutation.attributeName === 'class'
			) {
				handleStalledResults(mutation);
			}
		});
	});

	observer.observe(panelElement, { attributes: true });
};

/**
 * Observe Algolia Panel
 * Mutation Observer to observe class attribute changes to Algolia autocomplete panel.
 * This is to handle slower connections.
 */
const observePanel = () => {
	const parentElement = queryElement('#siteSearch');
	if (!parentElement) {
		console.warn('Parent element (#siteSearch) for aa-Panel not found.'); // eslint-disable-line no-console
		return;
	}

	const panelObserver = new MutationObserver(() => {
		const panelElement = queryElement('.aa-Panel');
		if (panelElement) {
			setupObserver(panelElement);
			panelObserver.disconnect();
		}
	});

	panelObserver.observe(parentElement, { childList: true, subtree: true });
};

// Handle form submission
const handleSubmit = event => {
	event.preventDefault(); // Prevent click

	// Refocus input and trigger Algolia search
	const inputField = queryElement('.aa-Input');
	if (inputField) {
		setTimeout(() => inputField.focus(), 0); // Use a short delay to ensure focus works

		/**
		 * Trigger Algolia's autocomplete behavior by simulating typing.
		 * This is so Algolia runs getSources to fetch updated results.
		 */
		inputField.dispatchEvent(new Event('input', { bubbles: true }));
	} else {
		console.warn('Search input field not found.'); // eslint-disable-line no-console
	}

	// Poll for redirect readiness
	const waitForRedirect = setInterval(() => {
		if (!status.resultsPending) {
			clearInterval(waitForRedirect);
			if (status.redirectURL) {
				window.location.replace(status.redirectURL);
			} else {
				console.warn('No redirect URL provided.'); // eslint-disable-line no-console
			}
		}
	}, 100); // Poll every 100ms
};

// Attach submit button listener
const attachSubmitButtonListener = () => {
	const submitButton = queryElement('.aa-SubmitButton');
	if (submitButton) {
		manageEventListener(submitButton, 'click', event => {
			status.resultsPending = true;

			setTimeout(() => {
				handleSubmit(event);
			}, 0); // Allow a slight delay so Algolia Autocomplete library can set local storage key for recent searches
		});
	} else {
		console.warn('Search submit button not found.'); // eslint-disable-line no-console
	}
};

// Initialise Autocomplete
const startAutocomplete = searchInstance => {
	const { setQuery } = autocomplete({
		container: '#headerAlgoliaSearchBox',
		panelContainer: '.header-algolia-search__results',
		plugins: [
			redirectUrlPlugin,
			recentSearchesPlugin,
			querySuggestionsPlugin,
			algoliaInsightsPlugin,
		],
		placeholder: placeholderText,
		detachedMediaQuery: 'none',
		openOnFocus: true,

		initialState: {
			query: sanitizeQuery(new URL(window.location).searchParams.get('text')),
		},

		onStateChange({ state, prevState }) {
			// Check if collections have updated and contain results
			if (
				// query && // Only act if there's a non-empty query
				state.status === 'idle' && // Ensure query has completed
				state.collections !== prevState.collections && // Collections have changed
				state.collections.some(collection => collection.items.length > 0) // There are results
			) {
				// Set redirectURL to defined redirect URL from Algolia response collections or our standard query search results.
				status.redirectURL =
					state.collections
						.flatMap(collection => collection.items)
						.find(item => item.urls?.length > 0)?.urls[0] || status.redirectURL;

				status.resultsPending = false;
				toggleFormActions();
			}
		},

		getSources({ query }) {
			status.redirectURL = searchItemUrl(query); // Set redirect URL for default search when fetching sources
			return debounce([
				productSuggestionsSource(sanitizeQuery(query)),
				// configuration for other sources, for example facets in autocomplete
			]);
		},
	});

	window.addEventListener('popstate', () => {
		setQuery(
			(searchInstance.helper && searchInstance.helper.state.query) || '',
		);
	});

	attachSubmitButtonListener();
};

observePanel();
export default startAutocomplete;
