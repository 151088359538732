import algoliasearch from 'algoliasearch/lite';
import getAttributeValue from '../../utils/getAttributeValue';

export const algoliaApplicationId = getAttributeValue(
	'.header-algolia-search',
	'data-search-application-id',
);
export const algoliaReadOnlyApiKey = getAttributeValue(
	'.header-algolia-search',
	'data-search-api-key',
);

export const searchClient = algoliasearch(
	algoliaApplicationId,
	algoliaReadOnlyApiKey,
);

const searchFn = searchClient.search.bind(searchClient);
searchClient.search = async queries => {
	const { results } = await searchFn(queries);

	// // // TEMP HACK
	document
		.querySelector('.ais-Hits-list')
		?.setAttribute('data-view-type', 'grid');
	// // // END TEMP HACK

	if (typeof window.hybrisEnrichment !== 'undefined') {
		window.od.algoliaSearchQuery = results[0]?.query;

		window.hybrisEnrichment.fetchEnrichedProductData(
			results[0].hits.map(hit => hit.legacyProductId),
		);
	}

	return { results };
};
