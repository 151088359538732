const prepareObjectData = data => {
	const objectsData = [];

	data?.forEach(productData => {
		let objectsDataRecord;

		// data without Algolia products, only productCodes (skus) are passed
		if (typeof productData === 'string') {
			objectsDataRecord = getObjectData(productData);
			objectsData.push(objectsDataRecord);
		} else {
			const { productCode, queryID } = productData;
			objectsDataRecord = getObjectData(productCode);
			objectsDataRecord.queryID = queryID;

			objectsData.push(objectsDataRecord);
		}
	});

	return objectsData;
};

export const dataToGetFromDataLayer = {
	// keyNameToDisplayInAlgolia: 'keyNameInDataLayer'
	price: 'skuPriceinVAT',
	quantity: 'skuQty',
};

export const getObjectData = objectID => {
	const purchasedObjectData = {};

	Object.entries(dataToGetFromDataLayer)?.forEach(([key, value]) => {
		purchasedObjectData[key] = getProductValuesFromDataLayer(objectID, value);
	});

	return purchasedObjectData;
};

export const getProductValuesFromDataLayer = (objectID, dataName) => {
	const products = window?.dataLayer?.[0]?.order?.products;
	let dataValue;

	products?.forEach(product => {
		if (product.skuID === objectID) dataValue = product[dataName];
	});

	return dataValue;
};

export default prepareObjectData;
