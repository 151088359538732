const retrieveAlgoliaSearchSkusFromStorageData =
	afterAlgoliaSearchStorageData => {
		if (!afterAlgoliaSearchStorageData) return [];

		const afterAlgoliaSearchSkus = [];

		afterAlgoliaSearchStorageData.forEach(productData => {
			afterAlgoliaSearchSkus.push(productData.productCode);
		});

		return afterAlgoliaSearchSkus;
	};

export default retrieveAlgoliaSearchSkusFromStorageData;
