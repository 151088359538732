const Cookie = {};

Cookie.get = name => {
	const cookieName = `${name}=`;
	const allCookies = document.cookie.split(';');

	for (let i = 0; i < allCookies.length; i += 1) {
		let cookie = allCookies[i];

		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1);
		}

		if (cookie.indexOf(cookieName) === 0) {
			return cookie.substring(cookieName.length, cookie.length);
		}
	}

	return null;
};

Cookie.set = (name, value = '', days = 365) => {
	const date = new Date();
	date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = `expires=${date.toUTCString()}`;

	document.cookie = `${name}=${value}; ${expires}; sameSite=Strict; secure; path=/`;
};

export default Cookie;
