export default function togglePillSwitch(selector = '.js-toggle-pill-switch') {
	const pillSwitch = document.querySelector(selector);

	if (pillSwitch) {
		const pillSwitchInput = pillSwitch.querySelector('input[type="checkbox"]');
		const isLoggedIn = pillSwitch.getAttribute('data-userLoggedIn');
		const currentChannel = pillSwitch.getAttribute('data-currentchannel');
		const incVatUrl = pillSwitch.getAttribute('data-incvat');
		const excVatUrl = pillSwitch.getAttribute('data-excvat');

		if (currentChannel === 'B2C') {
			pillSwitchInput.checked = true;
		} else {
			pillSwitchInput.checked = false;
		}

		if (!isLoggedIn) {
			pillSwitchInput.addEventListener('click', () => {
				pillSwitchInput.classList.toggle('checked');
				const vatUrl = pillSwitchInput.checked ? incVatUrl : excVatUrl;

				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'vat_switched',
						new_vat_channel: pillSwitchInput.checked ? 'b2c' : 'b2b',
					});
				}

				setTimeout(() => {
					window.location = `${vatUrl}`;
				}, 300);
			});
		}
	}
}
