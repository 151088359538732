const getNonAlgoliaPurchasedProductSkus = (
	afterAlgoliaSearchSkus = [],
	allProductSkus = [],
) => {
	const otherSkusSet = new Set(allProductSkus);

	afterAlgoliaSearchSkus?.forEach(productSku => {
		otherSkusSet.delete(productSku);
	});

	return Array.from(otherSkusSet);
};

export default getNonAlgoliaPurchasedProductSkus;
